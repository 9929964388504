import React from 'react'

import '../../scss/global.scss'
import '../../scss/header.scss'

class Header extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			nav: [
				{
					display: 'about',
					link: '/',
				},
			],
		}
	}

	render() {
		return (
			<>
				<nav className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
					<div className="container">
						<div>
							<a href="/">
								<img src="../assets/logos/scholarcade_logo_white_wide.png" alt="logo" style={{ width: '100px' }} />
							</a>
						</div>
					</div>
				</nav>
				<div className="heading-spacer"></div>
			</>
		)
	}
}

export default Header
