import React from 'react'

import '../../scss/global.scss'
import '../../scss/footer.scss'

class Footer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			nav: [
				{
					display: 'about',
					link: '/',
				},
				{
					display: 'privacy',
					link: '/privacy-policy',
				},
			],
		}
	}

	render() {
		return <div className="footer"></div>
	}
}

export default Footer
