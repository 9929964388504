import React from 'react'

import '../scss/global.scss'

class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<>
				<div className="section bg-dark-blue">
					<div className="container py-4 heading-container">
						<div className="row gx-5">
							<div className="col-md-12">
								<img src="../assets/logos/top_logo.jpg" className="mt-3 mb-5" alt="logo" />
								<h2>Practice speaking foreign languages on a spy adventure!</h2>
								<p>
									<i>Spywatch Lex</i>, by Scholarcade, is a supplemental immersive game designed to motivate students to practice speaking foreign languages. Our ACTFL-based
									curriculum is curated by trained educators to provide students and teachers with measurable outcomes based on "Can Do" statements.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="section bg-gray">
					<div className="container py-4">
						<div className="row gx-5 center-text align-items-start">
							<h3>Industry Awards and Grants</h3>
							<hr className="style-one" />
							<div className="col">
								<a href="https://ies.ed.gov/funding/grantsearch/details.asp?ID=4575" target="_blank">
									<div className="card min-height-md">
										<img src="../assets/logos/awards/ies_sbir.png" alt="ies sbir award" className="card-img-top p-3" />
										<div className="card-body">
											<h5>Small Business Innovation Research grant</h5>
											<h6>Institute of Education Sciences</h6>
										</div>
									</div>
								</a>
							</div>
							<div className="col">
								<a href="https://www.asugsvsummit.com/edtech-creator-challenge" target="_blank">
									<div className="card min-height-md">
										<img src="../assets/logos/awards/asu_gsv.png" alt="ies sbir award" className="card-img-top p-3" />
										<div className="card-body">
											<h5>Top 10% Finalist: EdTech Creator Challenge</h5>
											<h6>ASU+Global Silicon Valley Summit</h6>
										</div>
									</div>
								</a>
							</div>
							<div className="col">
								<a href="https://thelanguageflagship.tech/2019-launchpad/" target="_blank">
									<div className="card min-height-md">
										<img src="../assets/logos/awards/flagship_launchpad.png" alt="ies sbir award" className="card-img-top p-3" />
										<div className="card-body">
											<h5>Top 5 Finalist: EdTech Innovation</h5>
											<h6>The Language Flagship</h6>
										</div>
									</div>
								</a>
							</div>
							<div className="col">
								<a href="https://www.reimagine-education.com/" target="_blank">
									<div className="card min-height-md">
										<img src="../assets/logos/awards/qs_reimagine_education.png" alt="ies sbir award" className="card-img-top p-3" />
										<div className="card-body">
											<h5>Top 12% Finalist: Reimagine Education Award</h5>
											<h6>Wharton-Quacquarelli Symonds (QS)</h6>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="section bg-dark-gradient">
					<div className="container py-4">
						<div className="row gx-5 center-text">
							<div className="col-12">
								<h3>
									See <i>Spywatch Lex</i> in Action:
								</h3>
								<iframe
									width="720"
									height="415"
									src="https://www.youtube.com/embed/UOiib8lh-mA?si=5Vb0AJTIVIuCYYe0"
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								></iframe>
							</div>
						</div>
						<div className="row gx-5 center-text">
							<hr className="style-one" />
							<div className="col-sm-4">
								<p>
									<b>"Students are engaged in the story and eager to get to the next level"</b>
								</p>
								<p>
									<i>—Spanish teacher, Raleigh Charter HS</i>
								</p>
							</div>
							<div className="col-sm-4">
								<p>
									<b>"I was motivated to go further in my Chinese learnings"</b>
								</p>
								<p>
									<i>—Participating student</i>
								</p>
							</div>
							<div className="col-sm-4">
								<p>
									<b>"I like the game and the speaking aspect especially!"</b>
								</p>
								<p>
									<i>—Participating student</i>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="section bg-dark-blue">
					<div className="container py-4">
						<div className="row gx-5 center-text">
							<div className="col-md-12">
								<img src="../assets/images/mission.jpg" alt="graphic" style={{ width: '75%', maxWidth: '800px' }} />
							</div>
						</div>
					</div>
					<div className="container py-4">
						<div className="row gx-5 center-text">
							<div className="col-md-12">
								<h3 className="avenger">Explore Foreign Cities</h3>
								<h5>Speak with locals to find clues!</h5>
								<img src="../assets/images/explore_no_text.jpg" alt="graphic" style={{ width: '75%', maxWidth: '800px' }} />
							</div>
						</div>
					</div>
					<div className="container py-4">
						<div className="row gx-5 center-text">
							<div className="col-md-12">
								<h3 className="avenger">Sharpen Your Language Skills</h3>
								<h5>Practice speaking, listening, reading, and writing through spy missions!</h5>
								<img src="../assets/images/speak_no_text.jpg" alt="graphic" style={{ width: '75%', maxWidth: '800px' }} />
								<img src="../assets/images/foodcart_no_text.jpg" alt="graphic" style={{ width: '75%', maxWidth: '800px' }} />
							</div>
						</div>
					</div>
					<div className="container py-4">
						<div className="row gx-5 center-text">
							<div className="col-md-12">
								<h3 className="avenger">Speak Your Way to Victory!</h3>
								<h5>Convince enemy agents to provide intel using your language skills and disguises!</h5>
								<img src="../assets/images/convince_no_text.jpg" alt="graphic" style={{ width: '75%', maxWidth: '800px' }} />
							</div>
						</div>
					</div>
				</div>
				<div className="section bg-dark-blue smoke-background">
					<div className="container py-4">
						<div className="row gx-5 center-text" style={{ marginTop: '100px' }}>
							<div className="col-md-12">
								<img src="../assets/logos/spy_academy.png" alt="graphic" style={{ width: '75%', maxWidth: '300px', marginBottom: '20px' }} />
								<h3>The current game is under construction.</h3>
								<h5>
									Reach out to{' '}
									<a href="mailto:dan@scholarcade.com" style={{ textDecoration: 'underline' }}>
										dan@scholarcade.com
									</a>{' '}
									for inquiries.
								</h5>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default Home
